import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import FootnoteText from "../components/FootnoteText";
import { PageOuter } from "../components/PageOuter";
import Spacer from "../components/Spacer";

const PrivacyPage = (): JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);
  const title = "Privacy Policy";
  return (
    <Layout showScrollProgressBar>
      <SEO title={title} />
      <PageOuter title={title}>
        <h2 data-aos="fade" tabIndex={0}>
          Privacy Policy
        </h2>
        <Spacer />
        <div data-aos="fade">
          <FootnoteText tabIndex={0}>
            Last updated: 1 November 2023
          </FootnoteText>
        </div>
        <Spacer height={2} />
        <div data-aos="fade" data-aos-delay="250">
          <style>
            {`
            h4 {
              margin-top: 1rem;
            }`}
          </style>
          <div tabIndex={0}>
            <h4>Introduction</h4>
            <p>
              This is Blue Beck’s Privacy Policy. The policy describes how Blue
              Beck Limited (“Blue Beck”, “we”, “us” or “our”) and its
              third-party partners may collect, use, process and share
              information about you. This Privacy Policy applies to all of our
              website as well as all of our mobile applications and other
              software whether used on mobile devices, personal computers or on
              other platforms such as Facebook. It also applies to our marketing
              and advertising activities on all platforms and other services
              that we may provide to you from time to time. In this Privacy
              Policy we refer to our applications, games, websites, marketing
              and advertising activities and other services collectively as our{" "}
              {'"'}Services{'"'}. Services shall, however, not comprise those
              that we may develop, from time to time, for third parties under
              their instruction and branding; for such third-party services,
              their respective policies apply instead. By downloading, accessing
              and/or interacting with our Services, you agree to the collection
              and use of your information in accordance with this Privacy
              Policy, including consenting to our and our advertising partners
              {"'"} use of your data for targeted advertising purposes as
              described in the Marketing and Advertising sections below. Those
              sections also explain how you can withhold or withdraw your
              consent to the use of your data for targeted advertising purposes.
              If you have any other concerns about providing information to us
              or it being used as described in this Privacy Policy, you should
              not use our games or other Services.
            </p>
            <h4>Our Services</h4>
            <p>
              By downloading, accessing and/or using our Services, you consent
              to the collection of information about you by us. Most of this
              data comes directly from you or your device, such as device
              information, country and region, and gameplay information. We use
              this data to provide our games and Services to you, including for
              the purposes of optimisation, preventing fraud, customising
              in-game offers and verifying purchases. We may also use it for the
              other purposes set out in this Privacy Policy, including for
              Marketing and Advertising purposes. Most of the information that
              we collect about you comes directly from you when you user our
              Services. Broadly, the information we collect about you relates to
              the type of device you are using, how you use our Services (such
              as historical progress, purchases made, etc.) and may include
              information that you submit or when you allow us access when you
              connect to your social network accounts through our Services. We
              may also collect information from advertising platforms and
              partners and other third parties, such as information about
              purchases and interests. In more detail, depending on which of our
              Services you interact with, we may collect and process the
              following types of information:
            </p>
            <ul>
              <li>
                details about how you use and interact with our Services,
                advertising and other Services (for example, information about
                how and when you use our Services or visit our websites, what
                device you use to access our services or details regarding
                profile visits);
              </li>
              <li>
                information that you provide us with when you fill in forms,
                answer questions or complete surveys when using any of our
                Services, when you create an account with us or if you invite
                your friends to use our games and Services;
              </li>
              <li>
                the content of messages sent using our chat and messaging
                systems;
              </li>
              <li>
                if you contact us or respond to messages and communications that
                we send to you, we may keep a record of that correspondence;
              </li>
              <li>
                your interactions with us on our website and social media
                channels;
              </li>
              <li>
                information we collect via cookies and other similar
                technologies, as explained further below;
              </li>
              <li>
                information we collect about you from our other group companies
                or other third-party companies who have obtained your consent or
                have another legal right to share such information with us
                (including publishing partners, platforms, advertising platforms
                and partners and data aggregators who have obtained it). This
                may include attributes about you and your interests, as well as
                other games and services you use, demographic and general
                location information. We will use this information as described
                in this Privacy Policy and subject to any limitations in the
                privacy policy of the company that collected the information
                from you.
              </li>
              <li>
                If you choose not to connect your social media accounts, the
                information that we collect when you use our Services will be
                anonymous, but we will collect device level identifiers and
                other information associated with those identifiers, including:
                <ul>
                  <li>
                    the type of device(s) you are using to access and use our
                    Services, identifiers such as IP address, device
                    identifiers, ad identifiers, a specific user ID, and the
                    country or region that you are accessing these from; and
                  </li>
                  <li>
                    how you interact with our websites and Services (such as
                    which of our applications you are using, your progress
                    through these Services, session time and duration, purchases
                    made).
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              In some instances, when you make purchases on or through our
              website(s), we may collect certain payment information from you on
              behalf of our payment service providers, but this information is
              temporarily cached in a way that is unreadable by us. We do not
              collect or retain credit card information. Our payment service
              providers will provide us with information about your purchases so
              that we know if a purchase has been successful. When you purchase
              items via our mobile apps (such as those you can purchase in the
              App Store, on Facebook or on Google Play), we do not collect or
              store any payment information from you. We may, from time to time,
              ask you for your consent to collect other information from you or
              your device. For instance, we may invite you to allow us to access
              your contacts to enable us to invite them to play our games with
              you. If we do this or something similar, we will tell you what
              information we would like to collect, why we need it and what we
              {"'"}ll use it for. We, our suppliers and group companies, may use
              this information:
            </p>
            <ul>
              <li>
                to enable us to provide our games and other Services to you, to
                ensure that any purchases you make are verified on our servers
                and activated in the games, and to provide you with player
                support if you need it;
              </li>
              <li>
                to enable us to optimise our Services for you and for the device
                that you are using, as well as to deliver customised in-app
                events, offers and promotions;
              </li>
              <li>
                For the other purposes as set out in this Privacy Policy,
                including for Marketing and Advertising purposes; and
              </li>
              <li>
                to enable us to comply with laws that apply to us, to prevent
                fraud, to ensure compliance with our terms of service or, where
                necessary, to defend, exercise or establish our legal rights,
                including our rights under our Terms of Service.
              </li>
            </ul>
            <p>
              We may also share your information with third party publishers who
              develop and provide games and other Services to you on our behalf.
              We also analyse and use the information that we collect on an
              anonymised or aggregated basis for product development, to analyse
              the performance of our games, to troubleshoot and to improve and
              optimise the respective Services to ensure the best user
              experience for our users as a whole. A more detailed description
              of some of the ways that we use your information is set out below
              and in the other sections of this Privacy Policy.
            </p>
            <p>
              <u>Providing and optimising our Services</u>
            </p>
            <p>
              We use your information to enable us to provide our websites,
              advertising and other Services to you and to optimise all of them
              so that we can make them the best they can be for you and all our
              players. This will include use and analysis of aggregated data to
              make sure that our websites work properly on all devices and are
              as enjoyable as they can be for all our players.
            </p>
            <p>
              <u>Customer Service</u>
            </p>
            <p>
              We use your information, including data that you provide directly
              to us in communications, to provide you with support through our
              customer service channels when you need it. We may also use it to
              contact you, for example, as part of customer service or to send
              you updates about our Services. Finally, we will use your data to
              manage your account and relationship with us and improve your
              experience when you use our Services.
            </p>
            <p>
              <u>Analytics and Research</u>
            </p>
            <p>
              We use analytics tools, which may include third party analytics
              tools, to collect information about how you use our Services.
              Those tools may use cookies or other similar tracking
              technologies. We may use your data to carry out research, surveys
              or to engage directly with you, for example, by sending you
              communications (including by email), for these purposes. We may
              also create reports, analysis or similar services for use by us
              for the purposes of research or business intelligence, for
              example, to track potential problems or trends with our Services,
              or to test out new game features and content.
            </p>
            <p>
              <u>Social and Community</u>
            </p>
            <p>
              We may operate a number of social and community channels which we
              use to communicate with our players. If you choose to interact
              with those channels, we will receive your information and use it
              in connection with those channels, including to engage with you,
              and we may also republish your posts to those channels.
            </p>
            <p>
              <u>Promotions and Competitions</u>
            </p>
            <p>
              From time to time, we may run promotions or competitions during
              which we may collect certain personal information from you, such
              as your name, address, postcode, email address or other
              information or content submitted in order for you to claim a
              prize. Those competitions will have their own specific terms and
              conditions, but we will use personal information that you provide
              in relation to that competition for the purposes of running the
              competition. We may also publish the names and entries of
              competition entrants. That information may also be combined with
              other account information and will be processed, stored and shared
              in accordance with this Privacy Policy.
            </p>
            <p>
              <u>Crime and fraud prevention</u>
            </p>
            <p>
              We may use personal data that we hold to prevent cheating, crime
              or fraud. We may also need to pass your information to fraud
              prevention agencies and other organisations involved in crime and
              fraud prevention, such as the police.
            </p>
            <p>
              <u>Legal uses</u>
            </p>
            <p>
              We may use your data as required or permitted by any applicable
              law. As a specific example, if you live in the United Kingdom or
              the European Union, this could include for the purpose of
              providing VAT invoices where we are required to do so by law or
              where requested by you.
            </p>
            <h4>Our websites</h4>
            <p>
              When you visit our websites, such as{" "}
              <a href="https://care.king.com/">http://bluebeck.co.uk</a> and
              other websites and forums operated by us (“Websites”), we collect
              information about you, such as details about how you use and
              interact with such Websites. We may use the information we collect
              about you for a number of purposes, including for operating and
              optimising the Websites, in connection with our advertising
              purposes, and for research or analysis.
            </p>
            <h4>Social Networks</h4>
            <p>
              You can choose to connect some of our Services to your account
              with a third-party network (such as Facebook). If you do this,
              then your personal information will be visible to other players,
              and we may also use it for the other purposes set out in this
              Privacy Policy, including for Marketing and Advertising.
            </p>
            <h4>Marketing</h4>
            <p>
              Unless you opt out, as explained below, we and our third-party
              partners use information collected about you to send you marketing
              communications about other Blue Beck Services based on your
              interests. For example, we may send emails, serve ads within our
              Services or place ads on third party websites, apps and
              Internet-connected devices. We may use the information that we
              hold about you to promote our Services in a number of ways. This
              might include:
            </p>
            <ul>
              <li>
                advertising within third party websites, apps and
                Internet-connect devices;
              </li>
              <li>
                showing you promotions for other Blue Beck games in the games
                you play; or
              </li>
              <li>sending you marketing materials by email.</li>
            </ul>
            <p>
              We use information about you to try to make sure you only see
              marketing from us that might be of interest to you. This includes
              using information that we may have about you, such as:
            </p>
            <ul>
              <li>
                ad identifiers and other non-personal information collected from
                your device (for more information, see below);
              </li>
              <li>
                the apps you use and information about how you interact with our
                Services;
              </li>
              <li>your age, country or region, and gender; and</li>
              <li>
                other information that we might acquire from our third-party
                marketing partners or other third parties who have obtained your
                consent or have another legal right to share that information
                with us.
              </li>
            </ul>
            <p>
              When we market our Services in media published by other companies,
              we may use various third-party marketing partners to assist us on
              our behalf, and we may share information which we have collected
              about you with such third parties for these purposes. Our
              marketing partners may help us to serve ads to you by combining
              this information with data which they have collected about you
              elsewhere. They collect this information when you use their
              services or the websites and services of third parties. Our
              partners use this information to make assumptions about the kinds
              of advertising you would prefer to see. If they determine that you
              would be interested to see an advert for Blue Beck{"'"}s Services,
              they will serve you with an ad for our Services while you are
              using other websites and services. By accepting this Privacy
              Policy and downloading or continuing to use our Services, and
              unless you opt out of interest-based advertising as explained in
              this section, you consent to our marketing partners collecting and
              using information about you to improve advertising, targeting and
              measurement systems as described in their privacy policies. This
              Privacy Policy does not apply to the collection of your
              information by our marketing partners. We recommend that you
              review our partners{"'"} privacy policies for more information.
              You can exercise choices over the direct marketing we send you in
              the following ways:
            </p>
            <ul>
              <li>
                you can withhold your consent for us to send you direct
                marketing emails by opting out when we collect your email
                address or by electing not to share your email address with us
                when you first create an account using your Facebook details;
              </li>
              <li>
                you can remove yourself from receiving email marketing from us
                at any time by simply clicking the unsubscribe link in any
                direct marketing email we send to you or sending an email to
                privacy@bluebeck.co.uk; and
              </li>
              <li>
                to disable interest-based advertising (see below on how to
                adjust your preferences for interest-based advertising).
              </li>
            </ul>
            <p>
              You should note that if you opt out of receiving interest based or
              targeted advertising, you will still see advertising, and you may
              see advertising for our Services, but that advertising will not be
              targeted using your personal information.
            </p>
            <h4>Advertising</h4>
            <p>
              Our Services may include advertising for third party products and
              services. By accepting this Privacy Policy and downloading or
              continuing to use our Services, and unless you opt out of
              interest-based advertising as explained in this section, you
              consent to us and our advertising partners collecting and using
              information about you to improve advertising, targeting and
              measurement systems so that we can show you relevant third-party
              ads in our Services. To provide some of our Services, websites and
              interest-based advertising, we may use ad identifiers, cookies and
              similar technologies, which may be used by us to provide our
              Services to you and in order to collect data from your device.
              These technologies allow us and our third-party advertising
              partners to:
            </p>
            <ul>
              <li>recognise you and your device;</li>
              <li>provide interest-based advertising to you;</li>
              <li>
                allow our Services to interact with a third party social network
                or platform (where you have chosen to allow such interaction);
              </li>
              <li>
                allow us to understand how you interact with our websites (for
                example, where we use cookies such as the Google Analytics
                cookie on our Websites);
              </li>
              <li>
                allow our payment processors to process your payment
                instructions; and
              </li>
              <li>
                enable us and third parties to provide you with more customized
                services, for example, to provide our Services in the correct
                language.
              </li>
            </ul>
            <p>
              Ad identifiers are non-permanent, non-personal identifiers such as
              the Android advertising ID and/or Apple{"'"}s ID for advertising
              (the {'"'}IDFA{'"'}), which are uniquely associated with your
              device. Similar technologies include tracking pixels within ads.
              These technologies allow companies (including marketers and
              advertisers) to recognise your device when you use websites and
              applications. Cookies are small text files that are stored by your
              browser on your desktop computer or mobile device. You can
              withdraw your consent to cookies by updating the settings within
              your browser. You can choose to prevent your device{"'"}s ad
              identifier being used for interest-based advertising, or you can
              reset your device{"'"}s ad identifier, by changing the settings of
              your device. Settings vary from device to device, but you will
              usually find the ad identifier settings under {'"'}privacy{'"'} or{" "}
              {'"'}ads{'"'} in your device
              {"'"}s settings. If you choose to adjust your preferences in order
              to prevent these technologies, you may find that you cannot enjoy
              the full use of our Services. Please note that adjusting your
              preferences as described in this section does not mean you will no
              longer receive adverts, it only means the adverts you do see will
              be less relevant to your interests. We will also still use your
              device’s ad identifier to serve you with ads; for example, we may
              use this for frequency capping (in other words, to control the
              maximum number of times you see the same ad from us).
            </p>
            <h4>Your Rights</h4>
            <p>
              You have certain rights in connection with your personal
              information and how we handle it. You can exercise these rights at
              any time by contacting us via any of the methods set out in the
              “Contact Us” section below. Your rights include:
            </p>
            <ul>
              <li>
                Right of access. You have a right to know what information we
                hold about you and, in some cases, to have the information
                communicated to you. If you wish to exercise this right, please
                contact us letting us know that you wish to exercise your right
                of access and what information in particular you would like to
                receive. We reserve the right to ask for reasonable evidence to
                verify your identity before we provide you with any information.
                Please note that we may not be able to provide all the
                information you ask for, for instance, if the information
                includes personal information about another person. Where we are
                not able to provide you with the information that you have asked
                for, we will endeavour to tell you why. We will try to respond
                to any request for a right of access as soon as possible, but we
                will always do so within one month of receipt of your request
                and verification of your identity.
              </li>
              <li>
                Right to rectification. We try to keep the information that we
                hold about you accurate and up to date. Should you realise that
                any of the information that we hold about you is incorrect,
                please let us know at privacy@bluebeck.co.uk, and we will
                correct it as soon as we can.
              </li>
              <li>
                Right to Erasure. In some circumstances, you have a right to
                have some of the personal information that we hold about you
                deleted. Should you wish to have any information about you
                deleted, please contact us using the information below. Please
                note that in order to process your request, you must delete our
                games from your mobile devices and clear our cookies from any
                device where you have played our games in a web browser. Where
                we delete personal information about you, we may still retain
                some or all of that information for other purposes such as
                maintaining financial records, protecting or enforcing legal
                rights, maintaining marketing suppression lists or for technical
                reasons such as maintaining technical security or our database
                integrity. We may also retain your information in an anonymised
                form. In some instances, personal information about you that is
                visible through gameplay, such as username, avatar, your high
                scores and any chat messages, may be cached on other players
                {"'"}
                devices and we may not be able to remove or update that data
                from those devices, for example, if that device is not connected
                to a WiFi network.
              </li>
              <li>
                Data portability. In some circumstances, you may have the right
                to request that data which you have provided to us is provided
                to you, so you can transfer this to another data controller.
              </li>
              <li>
                Restriction of processing. In some cases, you may have the right
                to request a restriction of the processing of your personal
                data, such as when you are disputing the accuracy of your data
                held by us.
              </li>
              <li>
                Right to object. You have the right to object to the processing
                of personal data about you, which is processed on the grounds of
                legitimate interests (see “Legal Bases” below).
              </li>
              <li>
                Direct marketing opt out. If you have elected to receive direct
                marketing communications from us, you can change your mind at
                any time by following the opt out link in any marketing
                communication that is sent to you. If you have elected to
                receive more than one type of marketing communications from us,
                you may need to opt out of all of them individually. It may take
                a few days for us to update our records before any opt out is
                effective.
              </li>
            </ul>
            <p>
              You are not required to pay any charge for exercising your rights.
              If you make a request, we have one month to respond to you. Please
              contact us at privacy@bluebeck.co.uk if you wish to make a
              request. There are other ways in which you can control the
              personal information that we collect about you. For instance, you
              could disconnect your Facebook or other social network account
              from our Services, or delete our Services from your mobile device.
              You could also reset your mobile advertising identifier or limit
              ad tracking altogether using the settings in your phone. Finally,
              you could limit or prevent the use of cookies in your web
              browsers. More information on all of these options is provided
              elsewhere in this Privacy Policy.
            </p>
            <h4>Children</h4>
            <p>
              You must be over a certain age to play our games and use our
              Services, depending on where you live. For the full list of age
              restrictions by country, please see below. We do not knowingly
              collect or solicit personal information from or direct or target
              interest-based advertising to anyone under the ages set out below,
              or knowingly allow such persons to use our Services. If you
              believe that we might have any information from or about a child
              under the age set out below, please Contact Us.
            </p>
            <h4>Sharing</h4>
            <p>
              In addition to the other disclosures described in this Privacy
              Policy, we may also share your information:
            </p>
            <ul>
              <li>
                where we have your consent to do so (including as set out in
                this Privacy Policy);
              </li>
              <li>
                as reasonably necessary in order to provide the Services to you
                (for example, by providing your personal information to
                suppliers we may use to fulfil the Services or to communicate
                with you);
              </li>
              <li>
                where it is necessary to carry out your instructions (for
                example, to process a payment instruction we must provide your
                personal information to our payment processors);
              </li>
              <li>
                where your data is in an anonymous and aggregated format,
                meaning you could not be personally identified from it;
              </li>
              <li>
                as we reasonably believe is permitted by law or regulation or as
                is necessary to comply with any legal obligation, or in order to
                enforce or apply our terms and conditions and/or any other
                agreement with you; or to protect the rights, property, or
                safety of Blue Beck, our users, or others; and
              </li>
              <li>
                if there is a sale of the assets of Blue Beck or a corporate
                restructuring, or as a result of a change of control of Blue
                Beck or one of its group companies, or in preparation of any of
                these events. Any third party to which Blue Beck transfers of
                sells Blue Beck{"'"}s assets will have the right to continue to
                use the personal and other information that you provide to us in
                the manner set out in this Privacy Policy.
              </li>
            </ul>
            <p>
              We may also share your information with our service providers if
              necessary to enable them to provide services to us.
            </p>
            <h4>Security</h4>
            <p>
              We take appropriate security and technical measures to protect
              your information from unauthorised access, loss and misuse. In
              addition, we request that our suppliers who process personal
              information on our behalf take a range of security measures
              designed to help protect your personal information and maintain an
              appropriate level of security. However, we cannot guarantee the
              absolute security of your information online and offline, as the
              Internet, by its nature, is not a secure environment, and the
              nature of security risks is constantly evolving. As such, you
              should always take care when sharing your information online.
            </p>
            <h4>Legal Bases</h4>
            <p>
              In each case where we process your information, we do so lawfully
              in accordance with one of the legal basis set out under European
              data protection law. The legal bases which we rely upon are the
              following:
            </p>
            <ol>
              <li>
                Contractual Necessity. This covers data used by us in order to
                provide you with Services that you have requested - and that we
                have agreed - to provide to you, such as our apps, customer
                services, chat functionality and third party social network
                connectivity;
              </li>
              <li>
                Legal Obligation. This covers data used by us to comply with a
                legal obligation, such as to maintain records relating to tax or
                to respond to a valid legal request for certain data;
              </li>
              <li>
                Legitimate Interests. This covers data used by us for purposes
                that are not harmful to your privacy and can be reasonably
                expected within the context of your use of our Services, such as
                Marketing, Advertising, analytics, research and optimisation of
                our games and other Services; and
              </li>
              <li>
                Consent. This is used as a legal basis in the following
                contexts: (i) the collection of data stored on your device by us
                and our advertising partners, including device information, ad
                identifiers, IP address, country and region. You consent to this
                collection when you agree to install our game and you can
                withdraw your consent to this by uninstalling our games; (ii)
                the storage of data on your device by us and our advertising
                partners, including through the use of cookies. By accessing and
                using our Services, you consent to this storage and you can
                withdraw your consent by updating your cookie settings; and
                (iii) the use of your data by us and our partners for targeted
                advertising purposes (for more information, see Marketing and
                Advertising above). You consent to this collection when you
                accept this Privacy Policy and you can withdraw your consent to
                this processing by following the steps at How to adjust your
                preferences for interest-based advertising. Where we ask for
                your consent to use your data for any other purpose, we will
                make this clear at the point of collection, and we will also
                make clear how you can withdraw your consent.
              </li>
            </ol>
            <p>
              In this Privacy Policy, we have explained how you can opt out of
              certain types of processing that we undertake and where any
              processing is based on your consent, you can withdraw that consent
              at any time in the ways described in this Privacy Policy.
              Nonetheless, if you play our games, we will still collect some
              personal data about you and process it as otherwise described in
              this Privacy Policy. If you do not wish us to collect any personal
              data about you at all, you should cease playing our games and stop
              using our Services.
            </p>
            <h4>Retention</h4>
            <p>
              We will keep your information for as long as your account is
              active or we consider necessary for the purposes described in our
              Privacy Policy. We will also retain and use your information for
              as long as is required in order to comply with our legal
              obligations.
            </p>
            <h4>Location of storage</h4>
            <p>
              We store your information on servers that we control which are
              primarily based in the European Economic Area (EEA). We may also
              use servers and engage providers that are located outside the EEA
              in order to process and store your information and to provide
              services to us.
            </p>
            <h4>International Transfer</h4>
            <p>
              We may transfer your information to affiliated entities or third
              parties to jurisdictions outside the EEA. Please note that these
              countries outside of the EEA may not have the same data protection
              laws as your own jurisdiction. We take steps to ensure that there
              are adequate safeguards and mechanisms in place (including the use
              of EU model clauses) to allow the transfer of your information
              across borders outside of the EEA.
            </p>
            <h4>Changes to this Privacy Policy</h4>
            <p>
              We may update this Privacy Policy from time to time, and we will
              post the updated Privacy Policy on this page. Please come back to
              this page every now and then to make sure you are familiar with
              the latest version. Any new policy will be effective from the date
              it is published by us.
            </p>
            <h4>Contact us</h4>
            <p>
              If you have any questions or concerns about any of the matters in
              relation to this Privacy Policy, including to exercise any of your
              rights, you can contact us
            </p>
            <ol>
              <li>
                via email to{" "}
                <a href="mailto:privacy@support.king.com">
                  privacy@bluebeck.co.uk
                </a>
                ; or
              </li>
              <li>
                in writing to Blue Beck Ltd, Westminster House, 10 Westminster
                Road, Macclesfield, Cheshire, SK10 5BX.
              </li>
            </ol>
            <p>
              For information collected under this Privacy Policy, the data
              controller is Blue Beck Limited. Our Data Protection Officer can
              be contacted via dataprotection@bluebeck.co.uk.
            </p>
            <h4>How to Complain</h4>
            <p>
              You can also complain to the Information Commissioner’s Office
              (ICO) if you are unhappy with how we have used your data. The
              ICO’s address is: Information Commissioner’s Office, Wycliffe
              House, Water Lane, Wilmslow, Cheshire, SK9 5AF. Helpline number:
              0303 123 1113. ICO website:{" "}
              <a href="https://www.ico.org.uk/">https://www.ico.org.uk</a>
            </p>
          </div>
        </div>
      </PageOuter>
    </Layout>
  );
};

export default PrivacyPage;
