import React from "react";
import styled from "styled-components";
import { DefaultColours } from "../styles/styled";

const FootnoteText = ({
  style,
  children,
  colour,
  bold,
  tabIndex,
}: {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  colour?: keyof DefaultColours;
  bold?: boolean;
  tabIndex?: number;
}): JSX.Element => (
  <FootnoteStyles
    style={{ ...style, fontWeight: bold ? "bold" : "normal" }}
    colour={colour}
    tabIndex={tabIndex}
  >
    {children}
  </FootnoteStyles>
);

const FootnoteStyles = styled.div<{ colour?: keyof DefaultColours }>`
  color: ${(props) => props.theme.colours[props.colour || "midGrey"]};
  font-weight: normal;
  margin-top: 0;
`;

export default FootnoteText;
